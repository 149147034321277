.contact-cta {
	&__wrapper {
		padding: 3rem 0 3rem $spacing;
		position: relative;
	}

	&__text {
		opacity: 0;
		padding: $spacing;
		position: relative;
		transform: translate3d(5rem, 0, 0);
		transition: 1s;
		z-index: 2;

		.button.arrow {
			padding: 2rem 6rem 2rem 2rem;

			&:before {
				@include size(3.5rem);
				background-image: url('/wp-content/themes/transchemical/library/icons/src/arrow-right-white.svg') !important;
					background-position: center;
					background-size: 1.5rem;
				border: 2px solid $white;
				border-radius: 50%;
			}

			&:hover,
			&:focus {
				background-color: $blue;
				color: $white;

				&:before {opacity: 1;}
				&:after {opacity: 0;}
			}
		}
	}

	&__image {
		@include size(100%);
		overflow: hidden;
		position: absolute;
			top: 0;
			left: 0;

		img { @include object-fit(cover, center); }

		&.in-view {
			& + .contact-cta__text {
				opacity: 1;
				transform: translate3d(0, 0, 0);
			}
		}
	}

	@include media($screen-sm-max, 'max') {
		&__wrapper {margin: 0 -#{$spacing}};

		&__text {
			.button.arrow {
				padding: 1rem;

				&:before,
				&:after {content: none;}
			}
		}
	}

	@include media($screen-sm) {
		&__text {
			margin-left: auto;
			padding: $spacing*2
		};

		&__wrapper {
			padding: 4.5rem 0 4.5rem 1.5rem;
		}
	}

	@include media($screen-md) {
		&__wrapper {
			padding: 6rem 0 6rem 1.5rem;
		}

		&__text {
			max-width: calc(50% + 1.5rem);

			.button.arrow {font-size: 1.125rem;}
		}
	}
}