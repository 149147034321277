/**
 * Footer styles
 * DBS>Interactive
 *
 * Classname Structure
 *
 * The rational was to avoid using elements in the stylesheet and to limit nesting.
 *
 * 		site-footer - the footer element
 *
 * 			site-footer__navigation - footer menu
 * 				footer-menu__item - footer menu item
 *
 * 			site-footer__credits - copyright info container
 *
 * 			site-footer__copyright - copyright statement
 *
 */

.site-footer {
	overflow: hidden;
	margin-top: auto; // Sticky footer


	&__container {
		padding: #{$spacing*2} $spacing $spacing;
	}

	// Resets
	ul { list-style: none; margin: 0; padding: 0; }

	// Default link styles
	a:not(.arrow-link) {
		color: $textColor;
		text-decoration: none;

		&:hover { text-decoration: underline; }
	}

	&__cta {
		padding: $spacing 0;
	}

	.site-footer__logo {
		display: block;

		svg,
		img {
			max-width: 18em;
			padding-bottom: 2rem;
		}
	}

	&__left {
		text-align: center;
	}

	&__right {
		border-bottom: 1px solid $grayLighter;
		border-top: 1px solid $grayLighter;
		margin: $spacing -#{$spacing};
		padding: $spacing;
	}

	&__bottom {
		text-align: center;
	}

	&__navigation {
		padding-bottom: 1rem;

		.footer-menu__item {
			display: block;

			a {
				display: block;
				padding: 1em 0;

				&:after {
					content: '';
					display: block;
					border-bottom: 2px solid $blue;
					transform: scaleX(0);
					transform-origin: 100% 0;
					transition: transform .25s ease-in-out;
				}

				&:hover,
				&:focus {
					text-decoration: none;

					&:after {
						transform: scaleX(1);
						transform-origin: 0 100%;
					}
				}
			}
		}
	}

	&__logos {
		border-top: 1px solid $grayLighter;
        justify-content: space-around;
		margin: 0 -#{$spacing} -#{$spacing};
		padding-top: $spacing;

		img {
			margin-bottom: $spacing;
			max-height: 4rem;
			max-width: 12rem;
			min-width: 5rem;
			padding: 0 .5rem;
		}
	}

	.social-media {
		margin-top: $spacing;
		justify-content: center;
		text-align: right;

		&__link {
			display: inline-block;
			margin-left: 1rem;
		}

		a {
			display: block; width: 2em;
			
			&:hover,
			&:focus {
				svg path {fill: $yellow;}
			}
		}

		svg {
			display: block;
			max-height: 1.6rem;
		}

		svg path {fill: $white; transition: .25s ease-in-out;}
	}

	&__bottom {
		display: flex;
			align-items: flex-end;
			justify-content: space-between;
		font-weight: 300;
		font-size: .95rem;
		padding: 0;

		.legal-footer {
			display: inline-block;

			ul, li {display: inline-block;}

			a {font-weight: 300;}

			.legal-menu__item {
				border-left: 1px solid $gray;
				margin-left: .5rem;
				margin-top: 1rem;
				padding-left: .75rem;

				&:first-child {border: none;}
			}
		}
	}
    
    @include media($screen-xs) {
        &__logos {
            justify-content: center;
        }
    }

	@include media($screen-sm-max, 'max') {
		&__left {
			.arrow-link {margin-bottom: 2rem;}
		}

		&__bottom {
			flex-direction: column;

			#dbs-chev {margin: 3rem auto 0;}
		}
	}

	@include media($screen-sm) {
		.social-media {
			margin: 0;
			position: absolute;
				right: $spacing;
				bottom: 0;
		}

		&__left,
		&__right {width: 50%;}

		&__left {
			padding-right: $spacing;
			text-align: left;
		}

		&__right {
			border: none;
			margin: 0;
			padding: 0;
		}

		&__bottom {
			border-top: 1px solid $grayLighter;
			margin-top: $spacing*2;
			padding: $spacing 0 0;
			text-align: left;
		}

		&__navigation {
			padding-bottom: 1.5rem;

			ul {
				display: flex;
					flex-wrap: wrap;
			}

			.footer-menu__item {
				width: 33.33%;

				a {
					padding: 0 $spacing 1rem 0;
				}
			}
		}

		&__logos {
            justify-content: center;
			padding-top: 3rem;
		}
	}

	@include media($screen-md) {
		&__left {width: 40%;}
		&__right {width: 60%;}
	}
    
    @include media($screen-lg) {
        &__logos {
            justify-content: space-between;
        }
    }
}

