.products {
	position: relative;

	&__images {
		&-left,
		&-right {
			height: 50%;
			overflow: hidden;
			position: relative;

			img { @include object-fit(cover, center); }
		}
	}

	&__bottom {
		padding-bottom: $spacing; 
		padding-top: $spacing*2;
		position: relative;
		width: calc(100% - #{$spacing*2});

		&-links {
			margin-top: $spacing*2;

			a {
				border-bottom: 1px solid rgba($black, .14);
				display: block;
				font-size: 1.125rem;
				font-weight: 800;
				letter-spacing: 1px;
				padding: 1.25rem 0;
			}
		}

		&-left,
		&-right {
			opacity: 0;
			transition: 1s;
		}

		&-left {
			&.in-view {
				opacity: 1;

				+ .products__bottom-right {
					opacity: 1;
					transform: translate3d(0,0,0);
				}
			}
		}

		&-right {transform: translate3d(5rem,0,0);}
	}

	@include media($screen-sm-max, 'max') {
		padding-bottom: 3rem;

		&__images {
			position: absolute;
				top: $spacing*3;
				bottom: 0;
				left: 0;
			width: 100%;
		}

		&__bottom-links {
			a {
				&:last-child {
					border: none;
					padding-bottom: none;
				}
			}
		}

		&.one-img {
			.products__images-left {height: 100%;}
		}
	}

	@include media($screen-sm) {
		&__images {
			&-left,
			&-right {
				height: 0;
				padding-top: 40%;
				width: 50%;
			}
		}

		&__bottom {
			margin-top: -15rem;

			&-left {padding-right: $spacing*2;}

			&-links {
				display: flex;
					flex-wrap: wrap;
				margin-bottom: -1rem;
				margin-right: -2rem;
				position: relative;
				width: calc(100% + 2rem);

				&::before { // Mask the bottom borders of links on last row since the number will change
					border-bottom: 1px solid $grayLighter;
					content: '';
					position: absolute;
						bottom: 0;
						left: 0;
					width: 100%;
					z-index: 2;
				}

				a {
					margin-right: 2rem;
					width: calc(33.33% - 2rem);
				}
			}
		}

		&.one-img {
			.products__images-left {
				height: 35rem;
				padding-top: 0;
				width: 100%;
			}
		}

		@at-root .services + .products {
			margin-top: -10.5rem !important;
		}
	}

	@include media($screen-md) {
		&__bottom {

			&-links {
				a {width: calc(25% - 2rem);}
			}
		}
	}

	@include media($screen-lg) {
		&__bottom {
			max-width: calc( #{$containSize} - #{$spacing*2});
			padding: 3rem !important;

			&-links {
				margin-right: -3rem;
				width: calc(100% + 3rem);
	
				a {
					margin-right: 3rem;
					width: calc(25% - 3rem);
				}
			}
		}

		&.one-img {
			.products__images-left {
				height: 38rem;
			}
		}
	}
}