/**
 * DBS Slate Front Page Stylesheet
 * DBS>Interactive
 *
 * This stylesheet will only be loaded on the front page.
 */
/**
 * Global Mixins
 * DBS>Interactive
 */
/**
 * Helpful mixin for font size scaling.
 *
 * Decimal values should not be used here!
 * The results will not be what you'd expect.
 *
 * @usage
 * 		@include font-scale(-1) - smaller
 * 		@include font-scale(0) = 1em (.8em in mobile)
 * 		@include font-scale(1) - larger
 * 			etc...
 */
/**
 * Fixes clear bug in certain situations
 *
 * @group layout
 * @link http://fuseinteractive.ca/blog/understanding-humble-clearfix#.VJ853sAEo Understanding the humble clearfix
 * @example scss - Usage
 *   @extend clearFix; // That's It!
 */
.clearfix:before, .clearfix:after {
  content: "";
  display: table; }

.clearfix:after {
  clear: both; }

/**
 * An easy way to truncate text with an ellipsis. Requires the element to be block or inline-block.
 *
 * @group Typography
 * @link http://web-design-weekly.com/2013/05/12/handy-sass-mixins/ Handy Sass Mixins
 * @example scss - Usage
 *   .text-truncate {
 *	  @extend text-truncate;
 *   }
 */
/**
 * Hide On Breakpoint
 *
 * @param {Variable} $bp - Breakpoint variable, ie $largeScreen
 * @param {String} $determinate ('min') - Whether it is max-width or min-width based
 * @group General
 * @link https://github.com/GumbyFramework/Gumby/blob/master/sass/functions/_visibility.scss Gumby Framework
 * @example scss - Basic Usage
 *   .class{
 *	  @include hideOn($largeScreen);
 *   }
 *
 * @example scss - Reversed Determinate
 *   @include hideOn($baby, 'max');
 */
/**
 * Show On Breakpoint
 *
 * @param {Variable} $bp - Breakpoint variable, ie $largeScreen
 * @param {String} $determinate ('min') - Whether it is max-width or min-width based
 * @group General
 * @link https://github.com/GumbyFramework/Gumby/blob/master/sass/functions/_visibility.scss Gumby Framework
 * @example scss - Basic Usage
 *   .class{
 *	  @include showOn($largeScreen);
 *   }
 *
 * @example scss - Reversed Determinate
 *   @include showOn($baby, 'max');
 */
/**
 * Size mixin - Sets width and height.
 *
 * When only one argument is specified, both the height and width are set to the same value.
 *
 * @param width - required
 * @param height
 */
/**
 * REFLEX ISH GRID
 */
/**
 * Exponent function
 *
 * NOTE: This does NOT work for decimal values
 *
 *   @see: https://css-tricks.com/snippets/sass/power-function/
 */
/**
 * Object Fit
 *
 * If a browser supports object-fit it will use it with the params
 * given. Otherwise it will fall back on a more basic image centering method.
 *
 * @param size - required (cover, contain, auto, etc.)
 * @param position - required (center, left, right, top, bottom right, etc.)
 */
/**
 * Aspect Ratio
 *
 * Used to create a padding box that an image/video can be placed in.
 *
 * Example @include aspect-ratio(16, 9);
 */
/**
 * Slate Layout Mixins
 * DBS>Interactive
 */
/**
 * Default responsive padding mixin for layout content
 */
/**
 * Default responsive margin mixin for layout content
 */
/**
 * Mixin to keep space (either margin or padding) between cells consistent
 *
 * It takes 2 arguments:
 * 		@param $space_property = { 'margin'|'padding' }
 * 		@param $reverse = { true|false }
 */
/**
 * Responsive layout mixin to constrain a width to containSize.
 */
/**
 * These are all subset font files, meaning extraneous glyphs have been removed
 * to save file size.
 */
@font-face {
  font-family: 'Montserrat';
  src: url("/wp-content/themes/transchemical/library/fonts/montserrat/subset-Montserrat-ExtraBold.woff2") format("woff2"), url("/wp-content/themes/transchemical/library/fonts/montserrat/subset-Montserrat-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat';
  src: url("/wp-content/themes/transchemical/library/fonts/montserrat/subset-Montserrat-Bold.woff2") format("woff2"), url("/wp-content/themes/transchemical/library/fonts/montserrat/subset-Montserrat-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat';
  src: url("/wp-content/themes/transchemical/library/fonts/montserrat/subset-Montserrat-SemiBold.woff2") format("woff2"), url("/wp-content/themes/transchemical/library/fonts/montserrat/subset-Montserrat-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat';
  src: url("/wp-content/themes/transchemical/library/fonts/montserrat/subset-Montserrat-Regular.woff2") format("woff2"), url("/wp-content/themes/transchemical/library/fonts/montserrat/subset-Montserrat-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat';
  src: url("/wp-content/themes/transchemical/library/fonts/montserrat/subset-Montserrat-Light.woff2") format("woff2"), url("/wp-content/themes/transchemical/library/fonts/montserrat/subset-Montserrat-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat';
  src: url("/wp-content/themes/transchemical/library/fonts/montserrat/subset-Montserrat-ExtraLight.woff2") format("woff2"), url("/wp-content/themes/transchemical/library/fonts/montserrat/subset-Montserrat-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat';
  src: url("/wp-content/themes/transchemical/library/fonts/montserrat/subset-Montserrat-Thin.woff2") format("woff2"), url("/wp-content/themes/transchemical/library/fonts/montserrat/subset-Montserrat-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap; }

/**
 * Primary Font Family
 */
/**
 * Theme Variables - colors, font sizes, breakpoint etc.
 * All the variables will goes here based on project tech specification
 * DBS>Interactive
 */
/**
 * Type style definitions
 */
.contact-cta__wrapper {
  padding: 3rem 0 3rem 1.5rem;
  position: relative; }

.contact-cta__text {
  opacity: 0;
  padding: 1.5rem;
  position: relative;
  transform: translate3d(5rem, 0, 0);
  transition: 1s;
  z-index: 2; }
  .contact-cta__text .button.arrow {
    padding: 2rem 6rem 2rem 2rem; }
    .contact-cta__text .button.arrow:before {
      height: 3.5rem;
      width: 3.5rem;
      background-image: url("/wp-content/themes/transchemical/library/icons/src/arrow-right-white.svg") !important;
      background-position: center;
      background-size: 1.5rem;
      border: 2px solid #ffffff;
      border-radius: 50%; }
    .contact-cta__text .button.arrow:hover, .contact-cta__text .button.arrow:focus {
      background-color: #0060E5;
      color: #ffffff; }
      .contact-cta__text .button.arrow:hover:before, .contact-cta__text .button.arrow:focus:before {
        opacity: 1; }
      .contact-cta__text .button.arrow:hover:after, .contact-cta__text .button.arrow:focus:after {
        opacity: 0; }

.contact-cta__image {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0; }
  .contact-cta__image img {
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%; }
    @supports (object-fit: contain) {
      .contact-cta__image img {
        height: 101%;
        object-fit: cover;
        object-position: center; } }
  .contact-cta__image.in-view + .contact-cta__text {
    opacity: 1;
    transform: translate3d(0, 0, 0); }

@media screen and (max-width: 47.999em) {
  .contact-cta__wrapper {
    margin: 0 -1.5rem; }
  .contact-cta__text .button.arrow {
    padding: 1rem; }
    .contact-cta__text .button.arrow:before, .contact-cta__text .button.arrow:after {
      content: none; } }

@media screen and (min-width: 48em) {
  .contact-cta__text {
    margin-left: auto;
    padding: 3rem; }
  .contact-cta__wrapper {
    padding: 4.5rem 0 4.5rem 1.5rem; } }

@media screen and (min-width: 62em) {
  .contact-cta__wrapper {
    padding: 6rem 0 6rem 1.5rem; }
  .contact-cta__text {
    max-width: calc(50% + 1.5rem); }
    .contact-cta__text .button.arrow {
      font-size: 1.125rem; } }

.markets__left {
  margin-bottom: 3rem; }

.markets__image {
  height: 0;
  overflow: hidden;
  padding-top: 50%;
  position: relative; }
  .markets__image img {
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    transition: .5s;
    will-change: transform; }
    @supports (object-fit: contain) {
      .markets__image img {
        height: 101%;
        object-fit: cover;
        object-position: center; } }
  .markets__image:hover img {
    transform: scale(1.15) translate(-50%, -50%); }
  .markets__image:before, .markets__image:after {
    height: 100%;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; }
  .markets__image:after {
    background: linear-gradient(140deg, #0060DD 10%, rgba(0, 153, 240, 0.05) 80%);
    opacity: .6;
    transform: translate3d(0, 0, 0);
    z-index: 2; }
  .markets__image:before {
    background: linear-gradient(220deg, rgba(0, 0, 0, 0.8) 10%, rgba(41, 56, 64, 0.11) 90%);
    opacity: .6;
    z-index: 1; }

.markets__blocks a.arrow-link {
  display: block;
  letter-spacing: 2px !important;
  padding: 1rem 3rem 1rem 1rem; }
  .markets__blocks a.arrow-link:after {
    right: 1rem; }

.markets__block {
  margin-bottom: 1rem;
  position: relative; }
  .markets__block:last-child {
    margin-bottom: 0; }

.markets__block {
  opacity: 0;
  transform: translate3d(0, 2rem, 0);
  transition: 1s; }
  .markets__block.in-view {
    opacity: 1;
    transform: translate3d(0, 0, 0); }

@media screen and (min-width: 48em) {
  .markets__left {
    padding-right: 3rem; }
    .markets__left .arrow-link {
      margin-top: 2rem; }
  .markets__blocks, .markets__left {
    width: 100%; }
  .markets__blocks a.arrow-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    min-height: 4rem;
    padding: .5rem 3rem .5rem 1rem;
    position: absolute;
    bottom: -1px;
    width: 100%; }
  .markets__block {
    width: calc(33.33% - .5rem); }
    .markets__block:nth-last-child(2), .markets__block:nth-last-child(3) {
      margin-bottom: 0; }
  .markets__image {
    padding-top: 65%; }
    .markets__image::before, .markets__image::after {
      height: calc(100% - 4rem); } }

@media screen and (min-width: 62em) {
  .markets__left {
    margin-bottom: 0;
    padding-left: 3rem;
    position: relative;
    width: 40%; }
    .markets__left:after {
      height: 11rem;
      width: 14rem;
      background: url("/wp-content/themes/transchemical/library/images/swoosh.svg") no-repeat center;
      background-size: contain;
      content: '';
      position: absolute;
      left: 0;
      top: -5rem;
      z-index: -1; }
  .markets__block {
    width: calc(50% - .5rem); }
    .markets__block:nth-last-child(3) {
      margin-bottom: 1rem; }
  .markets__blocks {
    width: 60%; } }

@media screen and (min-width: 75em) {
  .markets__left {
    width: 55%; }
  .markets__blocks {
    width: 45%; } }

.products {
  position: relative; }
  .products__images-left, .products__images-right {
    height: 50%;
    overflow: hidden;
    position: relative; }
    .products__images-left img, .products__images-right img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .products__images-left img, .products__images-right img {
          height: 101%;
          object-fit: cover;
          object-position: center; } }
  .products__bottom {
    padding-bottom: 1.5rem;
    padding-top: 3rem;
    position: relative;
    width: calc(100% - 3rem); }
    .products__bottom-links {
      margin-top: 3rem; }
      .products__bottom-links a {
        border-bottom: 1px solid rgba(0, 0, 0, 0.14);
        display: block;
        font-size: 1.125rem;
        font-weight: 800;
        letter-spacing: 1px;
        padding: 1.25rem 0; }
    .products__bottom-left, .products__bottom-right {
      opacity: 0;
      transition: 1s; }
    .products__bottom-left.in-view {
      opacity: 1; }
      .products__bottom-left.in-view + .products__bottom-right {
        opacity: 1;
        transform: translate3d(0, 0, 0); }
    .products__bottom-right {
      transform: translate3d(5rem, 0, 0); }
  @media screen and (max-width: 47.999em) {
    .products {
      padding-bottom: 3rem; }
      .products__images {
        position: absolute;
        top: 4.5rem;
        bottom: 0;
        left: 0;
        width: 100%; }
      .products__bottom-links a:last-child {
        border: none;
        padding-bottom: none; }
      .products.one-img .products__images-left {
        height: 100%; } }
  @media screen and (min-width: 48em) {
    .products__images-left, .products__images-right {
      height: 0;
      padding-top: 40%;
      width: 50%; }
    .products__bottom {
      margin-top: -15rem; }
      .products__bottom-left {
        padding-right: 3rem; }
      .products__bottom-links {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-bottom: -1rem;
        margin-right: -2rem;
        position: relative;
        width: calc(100% + 2rem); }
        .products__bottom-links::before {
          border-bottom: 1px solid #E9EBEF;
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          z-index: 2; }
        .products__bottom-links a {
          margin-right: 2rem;
          width: calc(33.33% - 2rem); }
    .products.one-img .products__images-left {
      height: 35rem;
      padding-top: 0;
      width: 100%; }
    .services + .products {
      margin-top: -10.5rem !important; } }
  @media screen and (min-width: 62em) {
    .products__bottom-links a {
      width: calc(25% - 2rem); } }
  @media screen and (min-width: 75em) {
    .products__bottom {
      max-width: calc( 82rem - 3rem);
      padding: 3rem !important; }
      .products__bottom-links {
        margin-right: -3rem;
        width: calc(100% + 3rem); }
        .products__bottom-links a {
          margin-right: 3rem;
          width: calc(25% - 3rem); }
    .products.one-img .products__images-left {
      height: 38rem; } }

/**
 * DBS Chevron Styles
 * DBS>Interactive
 */
#dbs-chev {
  position: relative; }
  #dbs-chev svg {
    height: 2rem;
    max-width: 1rem; }
  #dbs-chev strong {
    font-size: 0.60883em;
    float: right;
    font-weight: normal;
    text-align: center;
    width: 15em; }
    @media (min-width: 48em) {
      #dbs-chev strong {
        font-size: 0.76104em; } }
    @media screen and (min-width: 48em) {
      #dbs-chev strong {
        width: 21em; } }
  #dbs-chev .text {
    background: #ffffff;
    max-width: 0;
    overflow: hidden;
    padding: .25em 0;
    position: absolute;
    right: 2em;
    top: 1px;
    transition: all .5s ease-out; }
  #dbs-chev.hover span, #dbs-chev:hover span, #dbs-chev:focus span {
    max-width: 23em; }

/**
 * Footer styles
 * DBS>Interactive
 *
 * Classname Structure
 *
 * The rational was to avoid using elements in the stylesheet and to limit nesting.
 *
 * 		site-footer - the footer element
 *
 * 			site-footer__navigation - footer menu
 * 				footer-menu__item - footer menu item
 *
 * 			site-footer__credits - copyright info container
 *
 * 			site-footer__copyright - copyright statement
 *
 */
.site-footer {
  overflow: hidden;
  margin-top: auto; }
  .site-footer__container {
    padding: 3rem 1.5rem 1.5rem; }
  .site-footer ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  .site-footer a:not(.arrow-link) {
    color: #000000;
    text-decoration: none; }
    .site-footer a:not(.arrow-link):hover {
      text-decoration: underline; }
  .site-footer__cta {
    padding: 1.5rem 0; }
  .site-footer .site-footer__logo {
    display: block; }
    .site-footer .site-footer__logo svg,
    .site-footer .site-footer__logo img {
      max-width: 18em;
      padding-bottom: 2rem; }
  .site-footer__left {
    text-align: center; }
  .site-footer__right {
    border-bottom: 1px solid #E9EBEF;
    border-top: 1px solid #E9EBEF;
    margin: 1.5rem -1.5rem;
    padding: 1.5rem; }
  .site-footer__bottom {
    text-align: center; }
  .site-footer__navigation {
    padding-bottom: 1rem; }
    .site-footer__navigation .footer-menu__item {
      display: block; }
      .site-footer__navigation .footer-menu__item a {
        display: block;
        padding: 1em 0; }
        .site-footer__navigation .footer-menu__item a:after {
          content: '';
          display: block;
          border-bottom: 2px solid #0060E5;
          transform: scaleX(0);
          transform-origin: 100% 0;
          transition: transform .25s ease-in-out; }
        .site-footer__navigation .footer-menu__item a:hover, .site-footer__navigation .footer-menu__item a:focus {
          text-decoration: none; }
          .site-footer__navigation .footer-menu__item a:hover:after, .site-footer__navigation .footer-menu__item a:focus:after {
            transform: scaleX(1);
            transform-origin: 0 100%; }
  .site-footer__logos {
    border-top: 1px solid #E9EBEF;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    margin: 0 -1.5rem -1.5rem;
    padding-top: 1.5rem; }
    .site-footer__logos img {
      margin-bottom: 1.5rem;
      max-height: 4rem;
      max-width: 12rem;
      min-width: 5rem;
      padding: 0 .5rem; }
  .site-footer .social-media {
    margin-top: 1.5rem;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: right; }
    .site-footer .social-media__link {
      display: inline-block;
      margin-left: 1rem; }
    .site-footer .social-media a {
      display: block;
      width: 2em; }
      .site-footer .social-media a:hover svg path, .site-footer .social-media a:focus svg path {
        fill: #FFC700; }
    .site-footer .social-media svg {
      display: block;
      max-height: 1.6rem; }
    .site-footer .social-media svg path {
      fill: #ffffff;
      transition: .25s ease-in-out; }
  .site-footer__bottom {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: end;
    align-items: flex-end;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-weight: 300;
    font-size: .95rem;
    padding: 0; }
    .site-footer__bottom .legal-footer {
      display: inline-block; }
      .site-footer__bottom .legal-footer ul, .site-footer__bottom .legal-footer li {
        display: inline-block; }
      .site-footer__bottom .legal-footer a {
        font-weight: 300; }
      .site-footer__bottom .legal-footer .legal-menu__item {
        border-left: 1px solid #a8a8a8;
        margin-left: .5rem;
        margin-top: 1rem;
        padding-left: .75rem; }
        .site-footer__bottom .legal-footer .legal-menu__item:first-child {
          border: none; }
  @media screen and (min-width: 30em) {
    .site-footer__logos {
      -ms-flex-pack: center;
      justify-content: center; } }
  @media screen and (max-width: 47.999em) {
    .site-footer__left .arrow-link {
      margin-bottom: 2rem; }
    .site-footer__bottom {
      -ms-flex-direction: column;
      flex-direction: column; }
      .site-footer__bottom #dbs-chev {
        margin: 3rem auto 0; } }
  @media screen and (min-width: 48em) {
    .site-footer .social-media {
      margin: 0;
      position: absolute;
      right: 1.5rem;
      bottom: 0; }
    .site-footer__left, .site-footer__right {
      width: 50%; }
    .site-footer__left {
      padding-right: 1.5rem;
      text-align: left; }
    .site-footer__right {
      border: none;
      margin: 0;
      padding: 0; }
    .site-footer__bottom {
      border-top: 1px solid #E9EBEF;
      margin-top: 3rem;
      padding: 1.5rem 0 0;
      text-align: left; }
    .site-footer__navigation {
      padding-bottom: 1.5rem; }
      .site-footer__navigation ul {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
      .site-footer__navigation .footer-menu__item {
        width: 33.33%; }
        .site-footer__navigation .footer-menu__item a {
          padding: 0 1.5rem 1rem 0; }
    .site-footer__logos {
      -ms-flex-pack: center;
      justify-content: center;
      padding-top: 3rem; } }
  @media screen and (min-width: 62em) {
    .site-footer__left {
      width: 40%; }
    .site-footer__right {
      width: 60%; } }
  @media screen and (min-width: 75em) {
    .site-footer__logos {
      -ms-flex-pack: justify;
      justify-content: space-between; } }

/**
 * DBS Slate Stylesheet
 * DBS>Interactive
 */
