.markets {
	&__left {
		margin-bottom: $spacing*2;
	}

	&__image {
		height: 0;
		overflow: hidden;
		padding-top: 50%;
		position: relative;

		img {
			@include object-fit(cover, center);
			transition: .5s;
			will-change: transform;
		}

		&:hover img {transform: scale(1.15) translate(-50%,-50%); }

		&:before,
		&:after {
			height: 100%;
			content: '';
			position: absolute;
				top: 0;
				left: 0;
			width: 100%;
		}

		&:after {
			background: linear-gradient(140deg, #0060DD 10%, rgba(0,153,240,0.05) 80%);
			opacity: .6;
			// This transform property prevents a really weird chrome bug where a sibling 
			// pseudo-element transform was causing this one to also shift and then revert.
			transform: translate3d(0,0,0);
			z-index: 2;
		}

		&:before {
			background: linear-gradient(220deg, rgba($black, .8) 10%, rgba(41,56,64,0.11) 90%);
			opacity: .6;
			z-index: 1;
		}
	}

	&__blocks {

		a.arrow-link {
			display: block;
			letter-spacing: 2px !important;
			padding: 1rem 3rem 1rem 1rem;
	
			&:after {right: 1rem;}
		}
	}

	&__block {
		margin-bottom: 1rem;
		position: relative;

		&:last-child {margin-bottom: 0;}
	}

	&__block {
		opacity: 0;
		transform: translate3d(0, 2rem, 0);
		transition: 1s;

		&.in-view {
			opacity: 1;
			transform: translate3d(0, 0, 0);
		}
	}

	@include media($screen-sm) {
		&__left {
			padding-right: $spacing * 2;
		
			.arrow-link {margin-top: 2rem;}
		}

		&__blocks,
		&__left {width: 100%;}

		&__blocks {
			a.arrow-link {
				display: flex;
					align-items: center;
				min-height: 4rem;
				padding: .5rem 3rem .5rem 1rem;
				position: absolute;
					bottom: -1px;
				width: 100%;
			}
		}

		&__block {
			width: calc(33.33% - .5rem);

			&:nth-last-child(2),
			&:nth-last-child(3) {margin-bottom: 0;}
		}

		&__image {
			padding-top: 65%;

			&::before,
			&::after {
				height: calc(100% - 4rem);
			}
		}
	}

	@include media($screen-md) {
		&__left {
			margin-bottom: 0;
			padding-left: 3rem;
			position: relative;
			width: 40%;

			&:after {
				@include size(14rem, 11rem);
				background: url('/wp-content/themes/transchemical/library/images/swoosh.svg') no-repeat center;
				background-size: contain;
				content: '';
				position: absolute;
					left: 0;
					top: -5rem;
				z-index: -1;
			}
		}

		&__block {
			width: calc(50% - .5rem);

			&:nth-last-child(3) {margin-bottom: 1rem;}
		}

		&__blocks {width: 60%;}
	}

	@include media($screen-lg) {
		&__left {
			width: 55%;
		}

		&__blocks {
			width: 45%;
		}
	}
}